import {applyMiddleware, compose, createStore} from 'redux';
import {persistStore} from 'redux-persist'
import persistCombineReducers from 'redux-persist/es/persistCombineReducers';
import storage from 'redux-persist/lib/storage';
import jwt from './reducer/JwtReducer';
import thunk from './middleware/Thunk';
import api from './middleware/Api';
import Config from 'Config';


const persistConfig = {
    key: 'root',
    storage: storage,
    whitelist: [
        'jwt'
    ]
};

const staticReducers = { jwt };
const initialState = {};
const middleware = [thunk, api];


// https://github.com/zalmoxisus/redux-devtools-extension
let enhancer = null;
if (Config.ENV !== 'prd') {
    const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            trace: true,
            shouldHotReload: false      // https://github.com/reduxjs/redux-devtools/issues/304
        })
        : compose;

    enhancer = composeEnhancers(applyMiddleware(...middleware));
}


// https://redux.js.org/recipes/code-splitting
const initializeStore = () => {
    const store = createStore(
        (Object.keys(staticReducers).length !== 0)
            ? createReducer()
            : () => {},
        initialState,
        (enhancer)
            ? enhancer
            : applyMiddleware(...middleware)
    )

    const persistor = persistStore(store, null)

    store.persistor = persistor

    store.asyncReducers = {}

    store.injectReducer = (key, asyncReducer) => {
        if (Reflect.has(store.asyncReducers, key)) return
        store.asyncReducers[key] = asyncReducer;
        store.replaceReducer(createReducer(store.asyncReducers));
        store.persistor.persist();
    }

    return store
};


const createReducer = asyncReducers =>
    persistCombineReducers(persistConfig, {
        ...staticReducers,
        ...asyncReducers
    });


export default initializeStore;